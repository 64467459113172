html {
  font-size: 62.5%;
}

body {
  min-height: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: inherit;
}
